import FileUploader from "../../../../../frappe/cypress/integration/file_uploader";
// /home/erpnext/frappe-bench/apps/frappe/cypress/integration/file_uploader.js
export default class CustomFileUploader extends FileUploader {
	upload_files() {
		this.dialog && this.dialog.get_primary_btn().prop("disabled", true);
		this.dialog && this.dialog.get_secondary_btn().prop("disabled", true);
		this.uploader.upload_files();
		this.dialog && this.dialog.get_primary_btn().prop("disabled", false);
		this.dialog && this.dialog.get_secondary_btn().prop("disabled", false);
	}
}
